import axios from 'axios';
import Cookies from 'js-cookie';

let DZ_request;

export const init_DZrequest = () => {
  // basic axios instance
  DZ_request = axios.create({
    baseURL: process.env.REACT_APP_WORK_ORDER_API
  });

  // set interceptors for request
  DZ_request.interceptors.request.use( (config) => {
    config.headers["Authorization"] = `Bearer ${Cookies.get("token")}`;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  // set interceptors for response
  DZ_request.interceptors.response.use( (request) => {
    return request;
  }, (error) => {
    if (error.response.status.toString() === '400' || error.response.status.toString() === '401') {
      if( sessionStorage.getItem('reloadUrl') !== undefined ) {
        let reloadUrl = process.env.REACT_APP_LOGIN_URL + sessionStorage.getItem('reloadUrl') + window.location.href;
        window.location.replace(reloadUrl);
      }
    }
    return Promise.reject( error );
  });
};

// ----------------- PRODUCTS -----------------
export const getProductsFromDZ = (data) => {
  return DZ_request.get('/products/', {
    params: data
  }).then( (res) => {
    return res.data;
  });
};

export const createProduct = (data) => {
  return DZ_request.post('/products/', data).then( (res) => {
    return res.data;
  });
};
