import React, { useEffect, useState, createContext } from 'react';
import { Route } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { init_DZrequest } from './api/dz-request.js';
import { init_request } from './api/request.js';
import ErpLayout from './components/layout.js';
import 'antd/dist/antd.css';
import '@ant-design/pro-table/dist/table.css';
import './App.css';
import queryString from 'query-string';

// used for getting tenantId for other components
export const UserContext = createContext();

function App() {
  const [ isLogin, setIsLogin ] = useState(false);
  const [ userInfo, setUserInfo ] = useState(null);

  useEffect( () => {

    if (process.env.REACT_APP_MODE === 'dev') { // local測試會進這裡
      init_request();
      init_DZrequest();
      setUserInfo({});
      setIsLogin(true);
    } else {
      let token = Cookies.get("token");
      let user = Cookies.get("user");

      if( token !== undefined && user !== undefined ) {
        let userObj = JSON.parse(user);

        axios({
          method: 'get',
          url: `${process.env.REACT_APP_USER_API}/permission/app?name=erp`,
          headers: { Authorization: `Bearer ${token}` }
        }).then( (res) => {
          if( res.data.access === true ) {
            let reloadUrl = 'login?tenantId=' + userObj.tenantId + '&redirectUri=';
            let logoutUrl = 'logout?tenantId=' + userObj.tenantId + '&redirectUri=';
            sessionStorage.setItem('reloadUrl', reloadUrl);
            sessionStorage.setItem('logoutUrl', logoutUrl);

            init_request();
            init_DZrequest();

            setUserInfo(userObj);
            setIsLogin(true);
          } else {
            alert('你沒有進入 ERP Tool 頁面的權限！');
            let loginUrl = process.env.REACT_APP_LOGIN_URL + 'login?tenantId=' + userObj.tenantId;
            window.location.replace(loginUrl);
          }
        }).catch( (err) => {
          alert('你沒有進入 ERP Tool 頁面的權限！');
          let loginUrl = process.env.REACT_APP_LOGIN_URL + 'login?tenantId=' + userObj.tenantId;
          window.location.replace(loginUrl);        
        });
      } else {
        if (sessionStorage.getItem("reloadUrl") ) {
          let reloadUrl = process.env.REACT_APP_LOGIN_URL + sessionStorage.getItem("reloadUrl") + window.location.href;
					window.location.replace(reloadUrl);
        }
        const params = queryString.parse(window.location.search);
				if( params.tenantId !== undefined && params.tenantId !== '' ) {
					// set loginUrl
					let redirectUri = window.location.href;
					let tenantId = params.tenantId;
					let loginUrl = process.env.REACT_APP_LOGIN_URL + 'login?tenantId=' + tenantId + '&redirectUri=' + redirectUri;
					// redirect
					window.location.replace(loginUrl);
				} else {
					let redirectUri = window.location.href;
					let loginUrl = process.env.REACT_APP_LOGIN_URL + 'login?redirectUri=' + redirectUri;
					// redirect
					window.location.replace(loginUrl);
				}
				setIsLogin(false);
      }
    }
  }, []);

  return (
    isLogin
    ?
      <UserContext.Provider value={ userInfo }>
        <Route path="/" component={ ErpLayout } />
      </UserContext.Provider>
    :
      null
  );
}

export default App;
