import React, { useState, useRef, useContext } from 'react';
import ProTable from '@ant-design/pro-table';
import { ConfigProvider, Button, message } from 'antd';
import { getProducts } from '../../api/request.js';
import { createProduct, getProductsFromDZ } from '../../api/dz-request.js';
import zhTWIntl from 'antd/lib/locale/zh_TW';

import { UserContext } from '../../App.js';

message.config({
  duration: 5
});

const TableList = () => {
  const userContext = useContext(UserContext);
  const [ products, setProducts ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ productColumns, setProductColumns ] = useState( [ {} ]);
  const [ showAntdSearchBar, setShowAntdSearchBar ] = useState(false);
  const [ stateParams, setStateParams ] = useState({});
  const tableHasFilter = useRef(false);

  const actionRef = useRef();
  const totalRef = useRef(0);
  const updateFlag = useRef(false);

  const fetchProdcutData = (tenantId, newParams) => {
    setLoading(true);
    let params = {
      ...newParams,
      tenantId: tenantId
    }
    
    return getProducts(params).then( (res) => { // 拿客戶product 跟數量
      if (res === null) {
        setLoading(false);
        return {
          data: [],
          success: true,
          total: 0
        }
      } else {
        // 整理column
        if (res.productColumns !== null ) {
          res.productColumns.push(
            {
              title: "是否同步",
              dataIndex: 'isUploadProduct',
              hideInSearch: true,
              valueEnum: {
                "N": {
                  text: "未同步",
                  status: "Default"
                },
                "Y": {
                  text: "已同步",
                  status: "Success"
                },
                "comparing": {
                  text: "比對中..."
                }
              },
              filters: [
                {
                  text: '已同步',
                  value: 'Y'
                },
                {
                  text: '未同步',
                  value: 'N'
                }
              ],
              onFilter: (value, record) => {
                if ( value === 'Y') {
                  return record.isUploadProduct === 'Y';
                } else {
                  return record.isUploadProduct === 'N' || record.isUploadProduct === 'R' ;
                }
              }
            }
          )
          setProductColumns(res.productColumns);
          setShowAntdSearchBar(res.showSearchBar);
        }

        // 整理資料
        let newDatas;
        if (res.product.data.length === 0) { // 客戶無product newDatas就存空array
          newDatas = [];
          setLoading(false); // 撈不到資料後關閉loading
          return {
            data: [],
            success: true,
            total: 0
          }
        } else { 
          // 整理物料資料 存到newDatas
          newDatas = res.product.data.map( (item, idx) => {
            item['key'] = idx;
            item['isUploadProduct'] = 'comparing';
            item['partNumber'] = item.partNumber.trimRight();
            item['name'] = item.name.trimRight();
            return item;
          })
        }

        setLoading(false); // 把loading關掉
        // setTotal(res.product.total);
        totalRef.current = res.product.total;

        if (res.product.total > 0) {
          let dzDataMap = {};
          return getProductsFromDZ({page: 'all'}).then( (rsp) => { // 客戶在dz資料庫的product全撈
            for (const dzProduct of rsp.data) {
              dzDataMap[dzProduct['number']] = true;
            }

            let compareResult = Array.from(newDatas);
            for (let i = 0; i < newDatas.length; i++) {
              if (dzDataMap.hasOwnProperty(compareResult[i].partNumber)) {
                compareResult[i] = Object.assign({}, compareResult[i], {isUploadProduct: 'Y'});
              } else {
                compareResult[i] = Object.assign({}, compareResult[i], {isUploadProduct: 'N'});
              }
            }
            setProducts(compareResult);
            setLoading(false);
            return {
              data: compareResult,
              success: true,
              total: res.product.total
            }
          });
        }
      }
    }).catch( (err) => {
      console.log('Products - get product from customer', err);
      message.error('網路異常，請稍後重試。');
      setProducts([]);
      setLoading(false);
      // setTotal(0);
      setShowAntdSearchBar(false);
      totalRef.current = 0;
      return {
        data: [],
        success: true,
        total: 0
      }
    });
  }

  const handleUpload = async(selectedRows) => {
    const hide = message.loading('正在上傳');
    const newProducts = Array.from(products);

    if (!selectedRows) return;
    hide();
    updateFlag.current = true;
    setLoading(true);
    let rowData = [];
    let requests = [];
    for( let i = 0; i < selectedRows.length; i++ ) {
      let row = selectedRows[i];
      if(row.isUploadProduct === 'Y') {
        message.warning(`${row['partNumber']} ${row['name'] } 已經上傳過了 略過`)
      } else {
        rowData.push(row);
        
        // 組memo 非partNumber和name但有顯示在前端的就放memo
        let memoParams = [];
        for(const [key, value] of Object.entries(row)) {
          if(key !== 'partNumber' && key !== 'name' && key !== 'isActive' && key !== 'isUploadProduct' && key !== 'key' && key !== 'category') {
            let titleKey = productColumns.find((item) => {
              return item.dataIndex === key
            })
            if (titleKey !== undefined) {
              memoParams.push(`${value}`);
            }
          }
        }
        memoParams = memoParams.join(',')
        
        // 組productParams
        let productParams = Object.assign({}, {
          is_active: row.isActive ? row.isActive : true, 
          name: row.name, 
          number: row.partNumber,
          memo: memoParams,
          category: row.category
        });

        requests.push(createProduct(productParams));
      }
    }
    let responses = await Promise.allSettled(requests);
    if (rowData.length > 0) {
      responses.forEach((res, resIndex) => {
        if (res.status === 'fulfilled') {
          let index = newProducts.findIndex((element) => {
            return element.partNumber === rowData[resIndex].partNumber;
          })
          let newRow = Object.assign({}, rowData[resIndex], {isUploadProduct: 'Y'})
          newProducts.splice(index, 1, newRow);
          message.success({ 
            content: `上傳成功 ${newRow['partNumber']} ${newRow['name'] }`
          });
        } else {
          let newRow = Object.assign({}, rowData[resIndex], {isUploadProduct: 'N'})
          message.error({ 
            content: `上傳失敗 ${newRow['partNumber']} ${newRow['name']}`
          });
        }
      })
    }
    
    actionRef.current.reload();
    actionRef.current.clearSelected();
    setProducts(newProducts);
    setLoading(false);
  };

  const handlePageChange = (pagination, filters, sorter) => {
    actionRef.current.clearSelected();
    let newParams = {};
    // 把current換成page、pageSize換成pageNum存到newParams
    for ( let [ key, value ] of Object.entries(pagination)) {
      key = ( key === 'current' ) ? 'page' : key;
      key = ( key === 'pageSize' ) ? 'pageNum' : key;
      if( key === 'page' ) {
        newParams[key] = value;
      }
      if( key === 'pageNum' ) {
        newParams[key] = value;
      }
    }

    setStateParams(newParams);
  }

  const requestData = async(params, sorter, filter) => {
    // search
    if (updateFlag.current === false) {
      let newParams = {};
      for( let [ key, value ] of Object.entries(params) ) {
        key = ( key === 'current' ) ? 'page' : key;
        key = ( key === 'pageSize' ) ? 'pageNum' : key;

        if( typeof(value) === 'number' ) {
          newParams[key] = value;
        }
        if( typeof(value) === 'string' ) {
          if( value.trim() !== '' )
            newParams[key] = value.trim();
        }
      }
      // filter
      let hasFilter = false;
      for( const value of Object.values(filter) ) {
        if( value !== null ) {
          hasFilter = true;
        }
      }
      tableHasFilter.current = hasFilter;

      let data = { data: Array.from(products, item => item), success: true, total: totalRef.current};
      if (!hasFilter) {
        data = await fetchProdcutData(userContext.tenantId, newParams)
      } else {
        if(Object.entries(filter).length > 0) {
          for( const [ key, value ] of Object.entries(filter) ) {
            if( value !== null ) {
              data.data = data.data.filter((item) => {
                return value.find( (filterValue) => {

                  return filterValue === item[key];
                }) !== undefined ? true : false;
              })
              data.total = data.data.length;
            }
          }
        }
      }
      return data;
    } else {
      updateFlag.current = false;
      return {
        data: products,
        success: true,
        total: products.length
      };
    }
  }
  
  return (
    <ConfigProvider locale={ zhTWIntl }>
      <ProTable
        loading={loading}
        headerTitle="產品匯入"
        actionRef={actionRef}
        rowKey="key"
        toolBarRender={(action, { selectedRows }) => [
          selectedRows && selectedRows.length > 0 && (
            <Button onClick={
              () => {
                handleUpload(selectedRows)
              }
            }>
              批量上傳
            </Button>
          ),
        ]}
        columns={productColumns}
        params={stateParams}
        rowSelection={{}}
        search={showAntdSearchBar}
        request={ requestData }
        postData={(data) => {
          // setUpdateFlag(false);
          // setLoading(false);
          return data;
        }}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize: 20,
          pageSizeOptions: [10, 20, 50, 100],
          //total: total,
          showTotal: (total) => {
            if( tableHasFilter.current ) {
              return `本頁篩選結果為 ${total} 筆`
            } else {
              return `共 ${total} 筆`
            }
          }
        }}
        onChange={handlePageChange}
      />
    </ConfigProvider>
  );
};

export default TableList;
