import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';

const CronTabForm = (props) => {
  const [form] = Form.useForm()
  const [time, setTime] = useState('1');
  const minuteArray = [...Array(40).keys()].map(val => ({ label: val + 20, value: val + 20 }));

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 16 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 12 }
    },
  };

  useEffect(() => {
    let string = props.cronString.split(" ");
    let newTime = string[1].split("/")['1'];

    form.setFields([{ name: 'minute', value: newTime }]);
    setTime(newTime);
  }, [props.cronString, form]);

  const changeTime = (value) => {
    form.setFields([{ name: 'minute', value: value }]);
    props.setCronString(`0 0/${value} * * * *`);
    setTime(value);
  }
  return (
    <Form
      {...formItemLayout}
      layout='inline'
      form={form}
      onValuesChange={(event, values) => {
        props.setCronString(`0 0/${values['minute']} * * * *`)
      }}
      initialValues={{ minute: time }}
    >
      <Form.Item name="minute" label="間隔分鐘">
        <Select value={time} onChange={changeTime} options={minuteArray} size='middle' />
      </Form.Item>
      <Tooltip title='除了間隔分鐘外，整點時也會同步一次'>
        <QuestionCircleOutlined />
      </Tooltip>
    </Form>
  );
};

export default CronTabForm;

