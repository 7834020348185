import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Switch, Divider } from 'antd';
import { listJobs, deleteJob, createJob, updateSfrToErpStatus } from '../../api/request.js';
import CronTabForm from './cron-tab-form.js'
import { UserContext } from '../../App.js';
import HistoryList from './history-list';

const JobList = () => {
	const userContext = useContext(UserContext);
  const [ orderJob , setOrderJob ]= useState(false);
	const [ cronOrder,  setCronOrder ] = useState('0 0/20 * * * *');
  const [ orderSwitchDisabled, setOrderSwitchDisabled ] = useState(true);
  const [ lastSyncTime, setLastSyncTime ] = useState(null);
  const [ lastSyncStatus, setLastSyncStatus ] = useState(null);
  const [ erpToSfrSwitchLoading, setErpToSfrSwitchLoading ] = useState(false);
  const [ sfrToErpSwitchLoading, setSfrToErpSwitchLoading ] = useState(false);

	const updateJobs = async (params) => {
		const { action } = params;
    setErpToSfrSwitchLoading(true);
    // 依據action狀態 呼叫不同function 然後再拿一次現在的狀態
    let result;
    if( action === 'delete' ){
      result = await deleteJob(params, userContext.tenantId);
    } else if( action === 'create' ){
      result = await createJob(params, userContext.tenantId);
    }

    if (result.status.toString() === '200') {
      setErpToSfrSwitchLoading(false);
      await getJobStatus();
    } else {
      //
    }
	};

	const getJobStatus = useCallback( async () => {
		let jobs = await listJobs({ tenantId: userContext.tenantId });
    if( jobs.success ) {
      let { orderJob, lastAutoSyncInfo } = jobs.data;
			if( !orderJob.hasOwnProperty('nextDate') ) {
				orderJob = Object.assign({}, orderJob, { nextDate: null, time: null });
			} else {
				setCronOrder(`0 0/${orderJob.time} * * * *`);
        if (lastAutoSyncInfo !== null) {
          setLastSyncTime(lastAutoSyncInfo.order.lastSync.time);
          setLastSyncStatus(lastAutoSyncInfo.order.lastSync.status);
        }
			}
			setOrderJob(orderJob);
    }

		setOrderSwitchDisabled(jobs.data.orderJob.switchDisabled);
  }, [ userContext.tenantId ]);

  const setSfrToErpStatus = async(params) => {
    setSfrToErpSwitchLoading(true);
    const { action } = params;
    let result = await updateSfrToErpStatus(params, userContext.tenantId);
    let tmpOrderJob = Object.assign({}, orderJob);
    tmpOrderJob["sfrToErp"] = action === 'create' ? true : false;
    if (result.status.toString() === '200') {
      setSfrToErpSwitchLoading(false);
      setOrderJob(tmpOrderJob);
    }
  }

  useEffect(  () => {
    getJobStatus();
  }, [ getJobStatus ]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '12px' }}>
        <h3>
          ERP製令單 -> DZ Connect 工單同步
        </h3>
				<Switch
					checkedChildren="開啟"
					unCheckedChildren="關閉"
					checked={ orderJob.status }
					disabled={ orderSwitchDisabled } // 不在erp_customer_info的就是true 無法點選開關
					onClick={(checked) => {
						setOrderJob(Object.assign({}, orderJob, { status: checked }));
						updateJobs({
							action : checked ? 'create' : 'delete',
							type: 'order',
							cronString: cronOrder,
              syncType: 'erpToSfr'
						});
          }}
          style={{ marginLeft: '12px' }}
          loading={ erpToSfrSwitchLoading }
				/>
      </div>
      <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '12px' }}>
        <h3>
          DZ Connect 工單同步 -> ERP製令單
        </h3>
				<Switch
					checkedChildren="開啟"
					unCheckedChildren="關閉"
					checked={ orderJob.sfrToErp }
					disabled={ orderSwitchDisabled } // 不在erp_customer_info的就是true 無法點選開關
					onClick={ (checked) => {
            setSfrToErpStatus({
							action : checked ? 'create' : 'delete',
							type: 'order',
              syncType: 'sfrToErp'
						})
          }}
          style={{ marginLeft: '12px' }}
          loading={ sfrToErpSwitchLoading }
				/>
      </div>
      {
        !orderJob.status
          ?
    				<CronTabForm
		    			cronString= { cronOrder }
				    	setCronString={ setCronOrder }
				    />
          :
            <div style={{ display: 'flex', gap: '24px' }}>
              <span>
                上次執行時間：
                {/* { orderJob.lastDate ? (new Date(orderJob.lastDate)).toLocaleTimeString('zh-tw') : 'null' } */}
                { lastSyncTime ? (new Date(lastSyncTime)).toLocaleString('zh-tw') : 'null' }
              </span>
              <>
                {lastSyncStatus === null ? null : lastSyncStatus === true ? <div style={{fontWeight: 'bold', color: "#87d068"}}>( 執行成功 )</div> : <div style={{fontWeight: 'bold', color: "red"}}>（ 客戶端連接失敗 )</div>}
              </>
              <span>
                下次執行時間：
                { orderJob.nextDate ? (new Date(orderJob.nextDate)).toLocaleString('zh-tw') : 'null' }
              </span>
            </div>
      }
      <Divider />
      <HistoryList />
		</div>
  );
};

export default JobList;

